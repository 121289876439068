<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 mb-5">
        <app-section :title="$t('Olvidé mi contraseña')"></app-section>
      </div>
      <div class="col-10 col-sm-6">
        <ForgotPasswordForm>
        </ForgotPasswordForm>
      </div>
    </div>

  </div>
</template>

<script>

import ForgotPasswordForm from "@/components/forms/ForgotPasswordForm";
import AppSection from "@/components/AppSection";
import {mapGetters} from "vuex";
export default {
  name: "ContactUs",
  components: {
    ForgotPasswordForm,
    AppSection
  },
  computed: {
    ...mapGetters(["cineClubSubscribed"])
  },
  props:{
    token: {
      default(){
        return null
      },
      type: String
    }
  },
  mounted: function() {
    if (this.cineClubSubscribed){
      this.$router.push({name: "club"});
    }
  },
  data: function(){
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
</style>