<template>
  <div>
    <div v-if="form_success">
        <h3 class="m-5 text-center">{{$t('Sigue las instrucciones enviadas a tu correo electrónico')}}</h3>
    </div>
    <div v-else>
      <form id="resetForm" ref="form" v-on:submit.prevent="onSubmit" >
        <div class="mb-4">
          <div class="form-group">
            <input  class="form-control mb-3"
                    :class="error_messages.email ? 'error' : '' "
                    ref="email"
                    name="email"
                    type="email"
                    :placeholder='$t("Email")'/>
            <p class="text-error d-none" >
              {{error_messages.email}}
            </p>
          </div>
        </div>
        <button class="btn btn-primary btn-block mb-5 btn-lg" type="submit">{{$t("Recuperar")}}</button>
      </form>
    </div>
  </div>
</template>

<script>

export default {
  name: "Form",
  props:{
  },
  data: function(){    
    return {
        error_messages: {
          email: "",
        },
        form_success: false
    };
  },
  computed:{
    submitEnabled(){
        return true;
    }
  },
  methods:{
    onSubmit(){
        this.error_messages = {
          email: "",
        };
        if(this.validateInputs()){
            var formData = new FormData(this.$refs["form"]);
            this.$store.dispatch('forgotPassword', formData).then(res => {
              this.formSuccess(res);
            }).catch(err => {
                const response = err.response;
                if(response.status == 400 && response.data && response.data.errors){
                    Object.keys(response.data.errors).map((key)=>{
                        this.error_messages[key] = response.data.errors[key].join(',');
                    });
                }
            });
        }
    },
    clearForm(){

    },
    validateInputs(){
        var valid = this.$refs.email.value.length > 0;

        if (!valid){
          this.error_messages.email = this.$t("Debe indicar un correo electrónico");
        }

        return valid;
    },
    formSuccess(){
        this.clearForm();
        this.form_success = true;
        this.$emit('form-success');
    }
  }
}
</script>
<style scoped lang="scss">
.fileIcon{
    font-size: 2.5rem;
}

.fake-btn{
    background-color: #000411;
    height: calc(1.8em + 0.75rem + 2px);
    padding: 0.5rem 0.75rem;
    font-weight: 500;
    color:#FFFFFF;
    border: 1px solid #ced4da;
    &:hover{
        background-color: #000000;
    }
}

.fake-btn.error, .form-control.error{
    border: 1px solid rgb(126, 46, 46);
}

.btn{
    padding: 0.5rem 0.75rem;
    //color: #FFFFFF;
    font-weight: 500;
}

.form-control{
    background-color: #000411;
    height: calc(2.5em + 0.75rem + 2px);
    padding: 0.75rem 0.9rem;
    font-weight: 500;
    color:#FFFFFF;
    border-color: white;
    &:focus{
        background-color: #000411;
        color:#EEEEEE;
    }
}
textarea{
    min-height: 90px;
    max-height: 250px;
}
.text-underlined{
    text-decoration: underline;
}
</style>